/* NAVIGATION MENU
================================*/
.navigation-menu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0rem;
  display: inline-block;
  float: left;
  color: inherit;

  @media (min-width: $landscape-width) {
	width: auto;
	@include flex();
	@include flexAlignCenterVertically();

	.navigation & {
	  @include flexSelfStretch();
	  @include flexStretch();
	}

	.navigation-logo-top & {
	  @include flexAlignCenter();
	}
  }
}

/* Navbar item */
.navigation-item {
  width: 100%;
  margin: 0;
  float: left;
  display: inline-block;
  color: inherit;

  @media (min-width: $landscape-width) {
	width: auto;
  }
}

/* Navbar link */
.navigation-link {
  width: 100%;
  padding: $navigation-link-padding;
  @include flex();
  @include flexAlignCenterVertically();
  position: relative;
  font-size: $navigation-link-font-size;
  letter-spacing: $navigation-link-letter-spacing;
  text-decoration: none !important;
  border-bottom: solid 1px $navigation-link-border-color;
  @include transition(all .3s ease);
  font-weight: 400;

  &:after {
	content: '';
	display: block;
	width: 0;
	height: 2px;
	background-color: var(--primary-accent-color);
	position: absolute;
	left: 10px;
	bottom: 40px;
	opacity: 0;
	transition: all .3s ease;
  }

  .navigation-item:last-child & {
	@media (min-width: $landscape-width) {
	  border-top: none;
	  border-bottom: none;
	}
  }

  .navigation-item:not(.navigation-brand-text):hover &,
  .navigation-item:not(.navigation-brand-text):focus &,
  .navigation-item:not(.navigation-brand-text).is-active & {
	color: var(--primary-accent-color);
  }

  @media (min-width: $landscape-width) {
	height: 100%;
	padding: $navigation-link-padding-landscape;
	line-height: $navigation-height;
	border: none;
  }

  .navigation-menu.navigation-social-menu + .navigation-menu:not(.navigation-social-menu) .navigation-item:first-child & {
	border-top: solid 1px $navigation-link-border-color;

	@media (min-width: $landscape-width) {
	  border-top: none;
	}
  }

  // Link with icons and text
  i {
	width: $navigation-link-text-icon-width;
	float: left;
	text-align: center;
	font-size: $navigation-link-text-icon-font-size;
	line-height: 0;

	// Font Awesome 5 icons
	&[class*=fa] {
	  font-size: $navigation-link-text-icon-font-awesome-size;
	}

	@media (min-width: $landscape-width) {
	  width: auto;
	}

	+ span {
	  padding-left: 8px;
	}
  }

  // Icon item
  .navigation-icon-item & {
	padding: $navigation-link-icon-item-padding;

	@media (min-width: $landscape-width) {
	  width: 100%;
	  margin: 0;
	  padding: $navigation-link-icon-item-padding-landscape;
	  border-left: solid 1px $navigation-link-border-color;
	}

	span:not(.submenu-indicator) {
	  @media (min-width: $landscape-width) {
		display: none;
	  }
	}

	.navigation-badge {
	  @media (min-width: $landscape-width) {
		right: 10px;
	  }
	}

	i {
	  @media (min-width: $landscape-width) {
		width: $navigation-link-icon-item-icon-width;
		height: $navigation-link-icon-item-icon-height;
		margin: 0;
		line-height: 26px;
		text-align: center;
		float: left;
		font-size: $navigation-link-icon-item-font-size;

		// Font Awesome 5 icons
		&[class*=fa] {
		  width: auto;
		  font-size: $navigation-link-icon-item-font-awesome-size;
		}
	  }
	}
  }

  .navigation-body-section + .navigation-menu:not(.navigation-social-menu) > .navigation-item:first-child > & {
	border-top: solid 1px $navigation-link-border-color;

	@media (min-width: $landscape-width) {
	  border-top: none;
	}
  }
}

@media (min-width: $landscape-width) {
  .navigation-item:not(.navigation-brand-text):hover,
  .navigation-item:not(.navigation-brand-text):focus,
  .navigation-item:not(.navigation-brand-text).is-active {
	.navigation-link {
	  &:after {
		width: calc(100% - 20px);
		opacity: 1;
	  }
	}
  }
}