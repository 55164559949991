/* MEGAMENU LIST
================================*/
.navigation-list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  @media (min-width: $landscape-width) {
    [class^=navigation-col]:last-of-type & {
      padding: 0;
    }
  }

  > li {
    width: 100%;
    margin: 0;

    &:last-child {
      margin-bottom: 5px;
    }

    &:first-child {
      margin-top: 5px;
    }

    @media (min-width: $landscape-width) {
      &:last-child {
        margin-bottom: 25px;
      }

      &:first-child {
        margin-top: 20px;
      }
    }

    > a:not(.crumina-button) {
      padding: 9px 10px 9px 25px;
      @include flex();
      @include flexAlignCenterVertically();
      position: relative;
      font-size: 14px;
      border: none;
      text-decoration: none;
      @include transition(color .3s, background .3s);
      color: var(--link-color);

      [class^=navigation-col]:last-of-type & {
        border-right: none;
      }

      &:hover {
        .crumina-icon {
          fill: var(--primary-accent-color);
        }

        .arrow-right {
          opacity: 1;
          margin-left: 20px;
        }
      }

      .arrow-right {
        margin-left: 10px;
        margin-right: 0;
        opacity: 0;
      }
      @media (max-width: $landscape-width) {
          font-size: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
          border-bottom: solid 1px $dropdown-link-border-color;
          border-top: solid 1px $dropdown-link-border-color;
          margin-top: -1px;
      }

      @media (min-width: $landscape-width) {
        padding: $navigation-list-link-padding;
        color: var(--link-color);
        font-size: $navigation-list-link-font-size;
      }
    }

    &:not(.navigation-list-heading) > a:after {
      content: "\25E5";
      position: relative;
      font-size: 9px;
      margin-left: 10px;
      opacity: 0;
      @include transform(rotate(45deg));
      transition: all .3s ease;

      display: none;
    }

    &:not(.navigation-list-heading) > a:hover {
      color: var(--primary-accent-color);

      &:after {
        opacity: 1;
      }
    }
  }
}
.navigation-list .navigation-list-heading {
  font-size: 16px;
  padding: 15px 10px;
  color: var(--link-color);
  font-weight: 700;

  @media (min-width: $landscape-width) {
    padding: 30px 10px 20px 30px;
    color: var(--heading-color);
    font-size: 18px;
  }

  > a {
    padding: $navigation-list-heading-padding !important;;

    &:hover {
      color: $navigation-list-heading-color !important;
      background-color: transparent !important;
    }
  }
}
